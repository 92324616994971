<template>
  <v-app fill-height class="w-100 grey lighten-4">
    <main class="w-100 my-3 fill-height mx-auto">
      <v-container fluid fill-height>
        <section class="w-100 d-flex align-items-center justify-content-center fill-height">
          <div class="col-sm-6 col-12 rounded-lg shadow-sm d-flex flex-column white fill-height">
            <div class="mt-5 w-75 me-2">
              <span class="h2 d-block mt-1 font-weight-bold">{{ $t('Mobile number verification') }}</span>
            </div>
            <div class="me-2 mt-5">
              <span class="font-weight-normal grey--text text--darken-1">{{ $t('A code has been sent to') }} <span
                  class="left-to-right">{{ mobile }}</span> {{ $t('Please enter it in the box below.') }}
              </span>
            </div>
            <div class="me-2 mt-4">
              <router-link to="/auth" class="font-weight-normal text-decoration-none h6">
                <span class="text--grey">{{ $t('Wrong number?') }}</span>
              </router-link>
            </div>

            <div class="d-flex justify-content-between align-items-center mt-auto">
              <span v-if="!resend" class="text--grey mx-auto">{{
                  formatTime(timerCount)
              }}</span>
              <span v-else class="text-info mx-auto pointer" @click="Login()">{{ $t('Resend') }}</span>
            </div>
            <div class="">
              <v-otp-input v-model="code" length="4" type="number" required
                class="col-8 offset-2 text-center left-to-right">
              </v-otp-input>
            </div>
            <div class="mt-1">
              <div class="mt-2 d-flex flex-row align-items-stretch">
                <v-btn color="primary" large class="col-8 flex-fill mx-2 13"
                       @click="ConfirmCode()" :loading="submit_loading"
                       :disabled="isSendCodeDisable">
                  {{ $t('Confirm') }}
                </v-btn>

                <v-btn color="red" large
                       class="col-4 text-white flex-fill mx-2 f13" @click="BackToLogin()"
                       :loading="submit_loading" :disabled="submit_loading">
                  {{ $t("Canceled") }}
                </v-btn>
              </div>
            </div>
          </div>
        </section>
      </v-container>
    </main>
  </v-app>
</template>
<script>
import apiService from "@/http/ApiService";

export default {
  data() {
    return {
      submit_loading: false,
      mobile: undefined,
      timerCount: 120,
      resend: false,
      code: "",
    };
  },
  computed: {
    isSendCodeDisable() {
      return !(this.code || this.submit_loading);
    },
  },
  methods: {
    ConfirmCode() {
      this.submit_loading = true;
      this.$store
        .dispatch("Verification", {
          mobileNumber: this.$store.getters.mobileNumber,
          userType: this.$store.getters.clientType === "CLIENT" ? "CLIENT" : "DOCTOR",
          verificationCode: this.code,
        })
        .then((response) => {
          this.submit_loading = false;
          if (response.status === 200) {
            if (!this.$store.getters.authenticated) {
              this.$store.getters.clientType === "CLIENT" ? this.$router.push("/register-client") : this.$router.push("/register-specialist");
            }
            else if (this.$store.getters.authenticated) {
              this.$store.getters.clientType === "CLIENT" ? apiService.saveFcmTokenClient(this.$store.getters.firebaseToken)
                  : apiService.saveFcmTokenDoctor(this.$store.getters.firebaseToken);
              this.$store.getters.clientType === "CLIENT" ? this.$router.push("/c/home") : this.$router.push("/s/home");
            }
            else {
              this.$swal({
                icon: "warning",
                text: this.$t('Error receiving information'),
                confirmButtonText: this.$t('OK')
              });
            }
          }
        })
        .catch((error) => {
          if (error) {
            this.submit_loading = false;
            this.$swal({
              icon: "warning",
              text: error.response.data.message,
              confirmButtonText: this.$t('OK')
            });
          }
        });
    },
    Login() {
      if (this.$store.getters.clientType === "CLIENT") {
        this.$store
          .dispatch("loginUser", { mobileNumber: this.mobile })
          .then((response) => {
            if (response.status === 200) {
              this.$store.commit("update_mobileNumber", this.mobile);
              this.$router.go("/verification-code");
            }
          })
          .catch((err) => {
            this.$swal({
              icon: "warning",
              text: err ? err.response.data.detail : this.$t("Error"),
              confirmButtonText: this.$t('OK'),
            });
          });
      }
      else if (this.$store.getters.clientType === "DOCTOR") {
        this.$store
          .dispatch("loginDoctor", { mobileNumber: this.mobile })
          .then((response) => {
            if (response.status === 200) {
              this.$store.commit("update_mobileNumber", this.mobile);
              this.$router.go("/verification-code");
            }
          })
          .catch((err) => {
            this.$swal({
              icon: "warning",
              text: err ? err.response.data.detail : this.$t("Error"),
              confirmButtonText: this.$t('OK'),
            });
          });
      }
    },
    BackToLogin() {
      this.$router.push("/login")
    },
    formatTime(second) {
      if (second === 0) {
        this.resend = true;
        return "";
      } else {
        this.resend = false;
        var date = new Date(0);
        date.setSeconds(second);
        var timeString = date.toISOString().substr(14, 5);
        return timeString;
      }
    },
  },
  mounted() {
    this.mobile = this.$store.getters.mobileNumber;
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  created() {
    if (this.$store.getters.isLoggedIn) {
      if (!this.$store.getters.authenticated) {
        if (this.$store.getters.clientType === "CLIENT") {
          this.$router.push("/register-client");
        }
        else if (this.$store.getters.clientType === "DOCTOR") {
          this.$router.push("/register-specialist");
        }
      } else if (this.$store.getters.authenticated) {
        if (this.$store.getters.clientType === "CLIENT") {
          this.$router.push("/c/home")
        }
        else if (this.$store.getters.clientType === "DOCTOR") {
          this.$router.push("/s/home");
        }
      }
    }
  }
};
</script>
