var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticClass: "w-100 grey lighten-4", attrs: { "fill-height": "" } },
    [
      _c(
        "main",
        { staticClass: "w-100 my-3 fill-height mx-auto" },
        [
          _c("v-container", { attrs: { fluid: "", "fill-height": "" } }, [
            _c(
              "section",
              {
                staticClass:
                  "w-100 d-flex align-items-center justify-content-center fill-height",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-sm-6 col-12 rounded-lg shadow-sm d-flex flex-column white fill-height",
                  },
                  [
                    _c("div", { staticClass: "mt-5 w-75 me-2" }, [
                      _c(
                        "span",
                        { staticClass: "h2 d-block mt-1 font-weight-bold" },
                        [_vm._v(_vm._s(_vm.$t("Mobile number verification")))]
                      ),
                    ]),
                    _c("div", { staticClass: "me-2 mt-5" }, [
                      _c(
                        "span",
                        {
                          staticClass:
                            "font-weight-normal grey--text text--darken-1",
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("A code has been sent to")) + " "
                          ),
                          _c("span", { staticClass: "left-to-right" }, [
                            _vm._v(_vm._s(_vm.mobile)),
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("Please enter it in the box below.")
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "me-2 mt-4" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "font-weight-normal text-decoration-none h6",
                            attrs: { to: "/auth" },
                          },
                          [
                            _c("span", { staticClass: "text--grey" }, [
                              _vm._v(_vm._s(_vm.$t("Wrong number?"))),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-between align-items-center mt-auto",
                      },
                      [
                        !_vm.resend
                          ? _c("span", { staticClass: "text--grey mx-auto" }, [
                              _vm._v(_vm._s(_vm.formatTime(_vm.timerCount))),
                            ])
                          : _c(
                              "span",
                              {
                                staticClass: "text-info mx-auto pointer",
                                on: {
                                  click: function ($event) {
                                    return _vm.Login()
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("Resend")))]
                            ),
                      ]
                    ),
                    _c(
                      "div",
                      {},
                      [
                        _c("v-otp-input", {
                          staticClass:
                            "col-8 offset-2 text-center left-to-right",
                          attrs: { length: "4", type: "number", required: "" },
                          model: {
                            value: _vm.code,
                            callback: function ($$v) {
                              _vm.code = $$v
                            },
                            expression: "code",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "mt-1" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "mt-2 d-flex flex-row align-items-stretch",
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "col-8 flex-fill mx-2 13",
                              attrs: {
                                color: "primary",
                                large: "",
                                loading: _vm.submit_loading,
                                disabled: _vm.isSendCodeDisable,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.ConfirmCode()
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("Confirm")) + " ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "col-4 text-white flex-fill mx-2 f13",
                              attrs: {
                                color: "red",
                                large: "",
                                loading: _vm.submit_loading,
                                disabled: _vm.submit_loading,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.BackToLogin()
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("Canceled")) + " ")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ]
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }